// REACT
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
// COMPONENTS
import Carousel from '../../components/Carousel/Carousel';
// SCSS
import classes from './Home.module.scss';


interface Props {
    name: string,
    logo: string
}

const Home: React.FC<Props> = props => {

    const { name, logo } = props;

    useEffect(() => {
        fetchData();
        window.scrollTo({top: 0, behavior:"smooth"});
    }, []);

    const [page, pageHandler] = useState(
        [
            {
                "company": "Firma d.o.o",
                "slogan": "We offer the best solutions for your business",
                "title": "Our Services",
                "carousel": [
                    "image.png",
                    "image.png"
                ],
                "section": [
                    {
                        "image": "image.png",
                        "title": "Service 1",
                        "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem."
                    },
                    {
                        "image": "image.png",
                        "title": "Service 2",
                        "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem."
                    },
                    {
                        "image": "image.png",
                        "title": "Service 3",
                        "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem."
                    }
                ]
            }
        ]
    );

    const fetchData = async () => {
        const response = await axios('/home.json');
        pageHandler(response.data);
    }

    const sections = page.map((el, k) => {

        const body = el.section.map((element, key) => {
            return (
                <motion.div
                    key={key + element.title}
                    className={classes.service}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                >
                    <img src={'/img/' + element.image} alt={element.title} />
                    <h4>{element.title}</h4>
                    <p>{element.text}</p>
                </motion.div>
            );
        });

        return (
            <React.Fragment key={k + el.company}>
                <section className={classes.hero}>
                    <div className={classes.hero_content}>
                        <Carousel name={el.company} slogan={el.slogan} images={el.carousel} css={null}/>
                    </div>
                </section>
                <section
                    className={classes.services}
                >
                    <h3>{el.title}</h3>
                    {body}
                </section>
            </React.Fragment>
        );
    });

    return (
        <main className={classes.PageTemplate}>
            <h1 className={`main_title ${classes.PageTemplate_title}`}><img className={classes.logo} src={logo} alt={name} /> {name}</h1>
            {sections}
        </main>
    );
};

export default Home;