// REACT
import React from 'react';
// REDUX
import { connect, ConnectedProps } from 'react-redux';
// COMPONENTS
import Button from '@mui/material/Button';
// SCSS
import classes from './Account.module.scss';

interface RootState {
  auth: {
    user: string | null;
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatch = {
  logout: () => ({ type: 'LOG_OUT' }),
  login: (data: string) => ({ type: 'LOG_IN', payload: data }),
}

const connector = connect(mapStateToProps, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux { }

const Account: React.FC<Props> = props => {

  const { user, logout, login } = props;

  console.log(user)

  return (
    <div className={classes.Account}>
      <div className={classes.Account_box}>
        <Button onClick={() => login('Hi!')} variant="outlined" color="success">Login</Button>
        <Button onClick={logout} variant="outlined" color="error">Logout</Button>
      </div>
    </div>
  );
};

export default connector(Account);