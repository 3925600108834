// REACT
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { motion, useViewportScroll } from "framer-motion";
// SCSS
import classes from './About.module.scss';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';



interface Props {
    name: string,
    logo: string
}

interface Page    {
    sections: {title: string, text: string, image:string}[]
}

interface icons {
    facebook: JSX.Element;
    twitter: JSX.Element;
    instagram: JSX.Element;
}

const About: React.FC<Props> = props => {

    const { name, logo } = props;

    const mapIcons = {
        facebook: <FaFacebook size='2em'/>,
        twitter: <FaTwitter size='2em'/>,
        instagram: <FaInstagram size='2em'/>
    }

    useEffect(() => {
        fetchData();
        window.scrollTo({top: 0, behavior:"smooth"});
    }, []);

    const [page, pageHandler] = useState<Page[]>(
        []
    );

    const fetchData = async () => {
        const response = await axios('/about.json');
        pageHandler(response.data);
    }

    let display = null;

    if(page.length > 0)
    {
        display = page.map((el, k) => {    

            const sections = el.sections.map((section, sectionk) =>{
                if(sectionk % 2 === 0)
                {
                    return (
                        <motion.div 
                            className={classes.section}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            >
                            <div className={classes.section_details}>
                                <h3>{section.title}</h3>
                                <p>{section.text}</p>
                            </div>
                            <img src={'/img/' + section.image} alt="About Us" className={classes.about_img} />
                        </motion.div>
                    );
                }
                else
                {
                    return (
                        <motion.div 
                            className={classes.section}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            >
                            <img src={'/img/' + section.image} alt="About Us" className={classes.about_img} />
                            <div className={classes.section_details}>
                                <h3>{section.title}</h3>
                                <p>{section.text}</p>
                            </div>
                        </motion.div>
                    );
                }

            });

            return (
                <React.Fragment>
                    <section className={classes.PageTemplate_box}>
                        <div className={classes.about_section}>
                            {sections}
                        </div>
                    </section>
                </React.Fragment>
            );
        });
    }

    return (
        <main className={classes.PageTemplate}>
            <h2 className={`main_title ${classes.PageTemplate_title}`}><img className={classes.logo} src={logo} alt={name} /> {name}</h2>
            {display && display}
        </main>
    );
};

export default About;