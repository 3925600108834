// REACT
import React, { Suspense, useEffect, useState } from 'react';
// ROUTING
import { Route, useLocation, Routes } from 'react-router-dom';
// REDUX
import { connect, ConnectedProps } from 'react-redux'
// LAYOUT
import Layout from './hoc/Layout/Layout';
import withRouter from './hoc/withRouter/withRouter';
// ANIMATION
import { AnimatePresence, motion } from 'framer-motion';
// import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
// COMPONENTS
import Account from './containers/Account/Account';
import Home from './containers/Home/Home';
import PageTableOne from './containers/PageTableOne/PageTableOne';
import Contact from './containers/Contact/Contact';
import About from './containers/About/About';
import Gallery from './containers/Gallery/Gallery';
// LAZY LOADING
// MODELS
import Nav_routes_model from './models/Nav_routes_model';
import axios from 'axios';


interface RootState {
  auth: {
    user: string | null;
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatch = {
  logout: () => ({ type: 'LOG_OUT' }),
}

const connector = connect(mapStateToProps, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux { }

// Aplication
const App = (props: Props) => {

  const mapPages: any = {
    PageTableOne,
    Account,
    Home,
    Contact,
    About,
    Gallery
  }

  useEffect(() => {
    fetchRoutes();
  }, []);

  const [publicRoutes, publicRoutesHandler] = useState([
    { path: '/', name: 'Home', component: 'Home', logo: '' }
  ]);

  // ROUTES
  const fetchRoutes = async () => {
    const response = await axios('/pages.json');
    publicRoutesHandler(response.data);
  }

  const { user } = props;

  // hook for current route location
  const location = useLocation();

  // animation for changing pages
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  // creating pages
  let pages = publicRoutes.map(({ path, component, name, logo }, k) => {

    const props = { name, logo };

    const Component = React.createElement(
      mapPages[component],
      props
    );

    return (
      <Route key={path} path={path} element={
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          {Component}
        </motion.div>
      } />
    );
  });

  // routes for nav element
  let navRoutes = publicRoutes.map(route => {
    return new Nav_routes_model(route.path, route.name);
  }).filter(route => route.path !== '*' && route.path !== '/');

  // return JSX
  return (
    <React.Fragment>
      <Suspense fallback={<p>Loading...</p>}>
        <Layout routes={navRoutes}>
          <AnimatePresence exitBeforeEnter initial={false}>
            <Routes location={location} key={location.pathname}>
              {pages}
            </Routes>
          </AnimatePresence>
        </Layout>
      </Suspense>
    </React.Fragment>
  );
};

// export App wraped with withRouter to pass parameter
export default withRouter(connector(App));