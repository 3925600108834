import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
// SCSS
import classes from './Carousel.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/free-solid-svg-icons"

interface Props {
    images: string[],
    name: string,
    slogan: string,
    css: string | null
}

const Carousel: React.FC<Props> = props => {

    const { name, slogan, images, css } = props;

    const [index, setIndex] = useState(0);

    const handlePrevClick = () => {
        setIndex((index - 1 + images.length) % images.length);
    };

    const handleNextClick = () => {
        setIndex((index + 1) % images.length);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index + 1) % images.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [index, images.length]);

    return (
        <div className={css ? css : classes.Carousel}>
            <div className={classes.Carousel_btn}>
                <div className={classes.Carousel_btn_arrow} onClick={handlePrevClick}><FontAwesomeIcon icon={faCircleArrowLeft} size='2xl' /></div>
                <div className={classes.Carousel_btn_arrow} onClick={handleNextClick}><FontAwesomeIcon icon={faCircleArrowRight} size='2xl' /></div>
            </div>
            <h1 style={{ position: "relative", zIndex: '3' }}>{name}</h1>
            <h2 style={{ position: "relative", zIndex: '3' }}>{slogan}</h2>
            {images.map((image, i) => (
                <motion.img
                    key={i}
                    src={'/img/' + image}
                    alt=""
                    style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                        zIndex: i === index ? 1 : 0,
                    }}
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: i === index ? 1 : 0,
                        transition: { duration: 0.5 },
                    }}
                />
            ))}

        </div>
    );
};

export default Carousel;