// REACT
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { motion, useViewportScroll } from "framer-motion";
// SCSS
import classes from './Contact.module.scss';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';



interface Props {
    name: string,
    logo: string
}

interface Page    {
    company: string,
    oib: string,
    iban: string,
    title: string,
    text: string,
    logo: string,
    address: string[],
    owner: {person: string, number: string}[],
    email: string[],
    website: string[],
    subtitle: string,
    working_hours: {
        pon_pet: string,
        sub: string
    },
    social_media: {media: string, link: string, image: string}[]
}

interface icons {
    facebook: JSX.Element;
    twitter: JSX.Element;
    instagram: JSX.Element;
}

const Contact: React.FC<Props> = props => {

    const { name, logo } = props;

    const mapIcons = {
        facebook: <FaFacebook size='2em'/>,
        twitter: <FaTwitter size='2em'/>,
        instagram: <FaInstagram size='2em'/>
    }



    useEffect(() => {
        fetchData();
        window.scrollTo({top: 0, behavior:"smooth"});
    }, []);

    const [page, pageHandler] = useState<Page[]>(
        []
    );

    const fetchData = async () => {
        const response = await axios('/contact.json');
        pageHandler(response.data);
    }

    let display = null;

    if(page.length > 0)
    {
        display = page.map((el, k) => {

            const addresses = el.address.map((address, addk) =>{
                return (
                    <p><strong>Adresa:</strong> {address}</p>
                );
            });

            const owners = el.owner.map((owner, ownerk) =>{
                return (
                    <React.Fragment>
                        <p><strong>Osoba za kontakt:</strong> {owner.person}</p>
                        <p><strong>Telefon:</strong> <a className={classes.tel} href={'tel:' + owner.number}>{owner.number}</a></p>
                    </React.Fragment>
                );
            });
    
            const emails = el.email.map((email, emailk) => {
                return (
                    <p><strong>E-mail:</strong> <a className={classes.tel} href={'mailto:' + email}>{email}</a></p>
                );
            });
    
            const websites = el.website.map((website, webk) => {
                return (
                    <p><strong>Web:</strong> {website}</p>
                );
            });
    
            const socialMedia = el.social_media.map((media, mediak) =>{
                const el = media.media
                return(
                    <li><a href={media.link} target="_blank">{mapIcons[el as keyof icons]}</a></li>
                );
            });
    
            return (
                <React.Fragment>
                    <div className={classes.contact_container}>
                        <div className={classes.contact_card}>
                            <img src={el.logo} alt={el.company} />
                            <p>{el.text}</p>
                            <h2>{el.company}</h2>
                            <p><strong>OIB:</strong> {el.oib}</p>
                            <p><strong>IBAN:</strong> {el.iban}</p>
                            {addresses}
                            {owners}
                            {emails}
                            {websites}
                        </div>
                        <div className={classes.working_hours}>
                            <h3>Radno vrijeme:</h3>
                            <p>ponedjeljak-petak: {el.working_hours.pon_pet}</p>
                            <p>subota: {el.working_hours.sub}</p>
                        </div>
                        <div className={classes.social_media}>
                            <h2>{el.subtitle}</h2>
                            <ul>
                                {socialMedia}
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            );
        });
    }

    return (
        <main className={classes.PageTemplate}>
            <h2 className={`main_title ${classes.PageTemplate_title}`}><img className={classes.logo} src={logo} alt={name} /> {name}</h2>
            {display && display}
        </main>
    );
};

export default Contact;